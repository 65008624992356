import { Preferences } from "@capacitor/preferences";
import { isPlatform } from "@ionic/react";
import { Diagnostic } from "@ionic-native/diagnostic";
import { logError } from "@src/appV2/lib/analytics";
import { UrgentShiftLocalStorage } from "@src/appV2/Location/constant";
import { ShiftWindow } from "@src/appV2/Shifts/Shift/types";
import { UrgentShift } from "@src/appV2/Shifts/UrgentShifts/api/useUrgentShifts";
import { Worker } from "@src/appV2/Worker/api/types";
import { USER_EVENTS } from "@src/constants";
import { orderBy } from "lodash";
import moment from "moment-timezone";

import { UrgencyType } from "./constants";

export const requestFullLocationAccess = async () => {
  if (!isPlatform("capacitor")) {
    return;
  }
  await requestLocationAccess();
  Diagnostic.switchToSettings();
};

export const requestLocationAccess = async () => {
  const locationStatus = await Diagnostic.getLocationAuthorizationStatus();

  if (locationStatus !== Diagnostic.permissionStatus.NOT_REQUESTED) {
    return;
  }

  await Diagnostic.requestLocationAuthorization();
};

export const requestLocationServiceEnable = () => {
  Diagnostic.switchToLocationSettings();
};

export const getOS = () => (isPlatform("ios") ? "ios" : "android");

export const getLabelsFromPageConfig = (pageConfig) => {
  return Object.keys(pageConfig).reduce((acc, key) => {
    const value = pageConfig[key];
    if (typeof value === "string") {
      return { ...acc, [key]: value };
    }

    const type = value?.type;
    const selector = value?.selector;
    const values = value?.values;

    if (type === "variable" && selector && values) {
      switch (selector) {
        case "os":
          return {
            ...acc,
            [key]: values[getOS()],
          };
      }
    }
    return acc;
  }, {});
};

export const handleException =
  (fn: Function) =>
  async (args = []) => {
    try {
      return await fn(...args);
    } catch (error: unknown) {
      logError(USER_EVENTS.URGENT_SHIFTS_UPDATE_LOCATION_ERROR, {
        error,
      });
    }
  };

export const checkIsNotificationSnoozed = (
  isOn: boolean,
  snoozedOn: Date | null,
  snoozedForDays = 1
): boolean => {
  if (snoozedOn && moment().diff(moment(snoozedOn), "days") < snoozedForDays) {
    return true;
  }

  return !isOn;
};

export const checkIsFullLocationPermissionRequired = async (
  permissionUpdate: Record<string, unknown>
): Promise<boolean> => {
  if (!isPlatform("capacitor")) {
    return false;
  }

  const { isLocationServicesEnabled } = permissionUpdate;

  if (isLocationServicesEnabled === true) {
    await Preferences.set({
      key: UrgentShiftLocalStorage.USER_DISABLED_FULL_LOCATION_PERMISSION,
      value: "false",
    });

    return false;
  }

  const { value: disabledPermission } = await Preferences.get({
    key: UrgentShiftLocalStorage.USER_DISABLED_FULL_LOCATION_PERMISSION,
  });
  if (disabledPermission === "true") {
    return true;
  }

  await Preferences.set({
    key: UrgentShiftLocalStorage.USER_DISABLED_FULL_LOCATION_PERMISSION,
    value: "true",
  });

  return true;
};

export const getPreviousLocationPermissionStatus = async (
  worker?: Worker
): Promise<Record<string, unknown>> => {
  const { value } = await Preferences.get({
    key: UrgentShiftLocalStorage.LAST_LOCATION_PERMISSION_STATUS,
  });

  const lastPermissionStatus = { ...worker?.mobile, agentId: worker?.userId };

  if (!value) {
    return lastPermissionStatus;
  }

  try {
    return JSON.parse(value);
  } catch (error) {
    console.error(error);
  }

  return lastPermissionStatus;
};

export const checkForUrgentShift = (shift) => {
  return shift.urgentlyBooked && shift.urgency === UrgencyType.NCNS;
};

export const sortUrgentShifts = (
  shifts: UrgentShift[],
  options: { searchParams: URLSearchParams; isWorkerAteamEnabled?: boolean }
): UrgentShift[] => {
  const { searchParams, isWorkerAteamEnabled = false } = options;
  const shiftStart = searchParams.get("shiftStart");
  // not opened from notification
  if (!shiftStart) {
    return isWorkerAteamEnabled
      ? orderBy(shifts, [(shift) => (shift.window === ShiftWindow.A_TEAM ? 0 : 1)], ["asc"])
      : shifts;
  }

  // opened from notification
  const sortIdentities = [
    (shift) => shift.start === shiftStart,
    (shift) => shift.originalAmount ?? 0,
  ];
  const sortOrders: Array<"asc" | "desc"> = ["desc", "desc"];
  if (isWorkerAteamEnabled) {
    sortIdentities.splice(1, 0, (shift) => (shift.window === ShiftWindow.A_TEAM ? 0 : 1));
    sortOrders.splice(1, 0, "asc");
  }

  return orderBy(shifts, sortIdentities, sortOrders);
};
