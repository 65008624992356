import { isDefined } from "@clipboard-health/util-ts";
import { Diagnostic } from "@ionic-native/diagnostic";
import { isAndroidPlatform, isCapacitorPlatform } from "@src/appV2/lib";
import { deprecatedDoNotUseLogError } from "@src/appV2/lib/analytics";
import {
  Location,
  Radar,
  RadarEvent,
  RadarLocationCallback,
  RadarLocationPermissionsCallback,
  RadarPlugin,
  RadarTrackCallback,
  RadarTrackingOptions,
  RadarTrackingOptionsDesiredAccuracy,
  RadarTrackingOptionsForegroundService,
  RadarTrip,
  RadarTripOptions,
  RadarUser,
} from "capacitor-radar";

export type RemoveHandler = () => Promise<void>;

export interface TrackOnceOptions {
  latitude: number;
  longitude: number;
  accuracy?: number;
}

export type RadarLocationHandler = (result: {
  /**
   * Note - We see undefined responses from `radar`, contradicting the Radar types.
   * https://github.com/radarlabs/capacitor-radar/blob/master/src/definitions.ts#L3-L8
   * capacitor-radar shows these as always provided, but we see errors in production, like
   * TypeError: undefined is not an object (evaluating 'r.events.some')
   */
  location?: Location;
  user?: RadarUser;
}) => void;

export type RadarLocationEventHandler = (result: {
  /**
   * Note - We see undefined responses from `radar`, contradicting the Radar types.
   * https://github.com/radarlabs/capacitor-radar/blob/master/src/definitions.ts#L3-L8
   * capacitor-radar shows these as always provided, but we see errors in production, like
   * TypeError: undefined is not an object (evaluating 'r.events.some')
   * Digging a little further, we see: https://www.npmjs.com/package/radar-sdk-js?activeTab=code
   * ```
export interface RadarTrackResponse extends RadarResponse {
  location?: Location;
  user?: RadarUser;
  events?: RadarEvent[];
}
   */
  events?: RadarEvent[];
  user?: RadarUser;
}) => void;

function isRadarTripOptions(options: any): options is RadarTripOptions {
  return isDefined(options.externalId);
}

export class LocationService {
  public _radarPlugin: RadarPlugin;
  private _startedTrip?: RadarTrip;

  constructor(radarPlugin?: RadarPlugin) {
    this._radarPlugin = radarPlugin ?? Radar;
  }

  public async startTrip(options: Parameters<RadarPlugin["startTrip"]>[0]): Promise<void> {
    const externalId = isRadarTripOptions(options.options)
      ? options.options.externalId
      : options.options.tripOptions.externalId;

    if (isDefined(this._startedTrip) && this._startedTrip.externalId === externalId) {
      return; // Do not start an already started trip
    }

    const { trip } = await this._radarPlugin.startTrip(options);
    this._startedTrip = trip;
  }

  public async completeTrip(): Promise<void> {
    await this._radarPlugin.completeTrip();
    this._startedTrip = undefined;
  }

  public async cancelTrip(): Promise<void> {
    await this._radarPlugin.cancelTrip();
    this._startedTrip = undefined;
  }

  public startTracking(
    options: RadarTrackingOptions,
    foregroundService?: RadarTrackingOptionsForegroundService
  ): void {
    if (isAndroidPlatform() && isDefined(foregroundService)) {
      this._radarPlugin.setForegroundServiceOptions({ options: foregroundService });
    }

    this._radarPlugin.startTrackingCustom({ options });
  }

  public stopTracking(): void {
    this._radarPlugin.stopTracking();
  }

  public setUserId(userId: string): void {
    this._radarPlugin.setUserId({ userId });
  }

  public async getLocation(options: {
    desiredAccuracy: RadarTrackingOptionsDesiredAccuracy;
  }): Promise<RadarLocationCallback> {
    return this._radarPlugin.getLocation(options);
  }

  public async trackOnce(options?: TrackOnceOptions): Promise<RadarTrackCallback> {
    return this._radarPlugin.trackOnce(options);
  }

  public async addLocationListener(callback: RadarLocationHandler): Promise<RemoveHandler> {
    const listenerHandle = await this._radarPlugin.addListener("location", callback);
    return listenerHandle.remove;
  }

  public async addLocationEventListener(
    callback: RadarLocationEventHandler
  ): Promise<RemoveHandler> {
    const listenerHandle = await this._radarPlugin.addListener("events", callback);
    return listenerHandle.remove;
  }

  public initialize(publishableKey: string): void {
    this._radarPlugin.initialize({ publishableKey });
  }

  public getLocationPermissionsStatus(): Promise<RadarLocationPermissionsCallback> {
    return this._radarPlugin.getLocationPermissionsStatus();
  }

  public requestLocationPermissions(): void {
    this._radarPlugin.requestLocationPermissions({ background: true });
  }

  public async isLocationAvailable(): Promise<boolean> {
    return isCapacitorPlatform() && (await Diagnostic.isLocationAvailable());
  }

  public async getGeoCodeAddress(
    address: string
  ): Promise<{ latitude: number; longitude: number } | undefined> {
    try {
      const { addresses } = await this._radarPlugin.geocode({ query: address });

      const firstAddress = addresses?.[0];

      if (!isDefined(firstAddress)) {
        return undefined;
      }

      return { latitude: firstAddress.latitude, longitude: firstAddress.longitude };
    } catch (error) {
      deprecatedDoNotUseLogError({
        message: `Radar SDK address geocoding error, ${error}`,
      });
    }
  }

  public async isWithinFacilityGeofence(facilityId: string): Promise<boolean | undefined> {
    const locationsIsAvailable = await this.isLocationAvailable();

    if (!locationsIsAvailable) {
      return undefined;
    }

    const trackCallBack = await this._radarPlugin.trackOnce();
    const geofences = trackCallBack.user?.geofences ?? [];
    return geofences.some((geofence) => geofence?.externalId === facilityId);
  }
}

export const locationService = new LocationService();
