import { isIosPlatform } from "@src/appV2/lib";

interface Props {
  locationIsAlwaysShared: boolean;
}

export function ReasonText(props: Props) {
  if (!props.locationIsAlwaysShared) {
    return (
      <>
        You must enable <b>Location</b> permission to book shifts and access the Clipboard Health
        marketplace. We collect and use this location information for many features in the app.
      </>
    );
  }
  return (
    <>
      You must enable the <b>{isIosPlatform() ? "Always" : "Allow all the time"}</b> and{" "}
      <b>Precise Location</b> permissions to book shifts and access the Clipboard Health
      marketplace. We collect and use this location information for many features in the app.
    </>
  );
}
