import "../style.scss";
import { ExternalLink, Image, Li, Text, Ul } from "@clipboard-health/ui-react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from "@mui/material";
import { isOsWithoutAlwaysAllowOption } from "@src/appV2/Location/utils";
import { useEffect, useState } from "react";

import { ReasonText } from "./reasonText";
import { GeneralLocationPrimerModalProps } from "../model";

const locationBenefits = [
  {
    src: "../../assets/icons/dollarsign.circle.fill.svg",
    label: "Verify the completion of shifts in order to distribute your earnings via InstantPay",
  },
  {
    src: "../../assets/icons/magnifyingglass.circle.fill.svg",
    label: "Show you nearby, high-paying Urgent Shifts",
  },
  {
    src: "../../assets/icons/building.2.fill.svg",
    label:
      "Improve users experience by confirming to facilities that you are on your way to an upcoming shift",
  },
];

export function GeneralLocationPrimerModal(props: GeneralLocationPrimerModalProps) {
  const { isOpen, onAllowLocationClick, onDeny, onDidDismiss } = props;
  const [isOsWithoutAlwaysAllow, setIsOsWithoutAlwaysAllow] = useState(false);

  useEffect(() => {
    if (isOpen) {
      isOsWithoutAlwaysAllowOption()
        .then((result) => setIsOsWithoutAlwaysAllow(result))
        .catch(() => setIsOsWithoutAlwaysAllow(false));
    }
  });

  return (
    <Dialog open={isOpen} onClose={() => onDidDismiss()} maxWidth="md">
      <Stack direction="row" justifyContent="center" alignItems="center" sx={{ paddingTop: 2 }}>
        <Image src="assets/logo/bunny.png" alt="Clipboard Health" height={100} />
      </Stack>
      <DialogTitle>Enable location permissions</DialogTitle>
      <DialogContent sx={{ paddingX: 4 }}>
        <DialogContentText sx={{ textAlign: "center" }}>
          <ReasonText locationIsAlwaysShared={!isOsWithoutAlwaysAllow} />
        </DialogContentText>
        <Ul
          sx={{
            listStyleType: "none",
            paddingTop: 2,
            paddingX: 0,
          }}
        >
          {locationBenefits.map((item) => (
            <Li key={item.src} sx={{ paddingBottom: 1 }}>
              <Box
                sx={{
                  display: "flex",
                }}
              >
                <Image src={item.src} alt={item.label} height={30} sx={{ paddingRight: 2 }} />
                <Text variant="body2">{item.label}</Text>
              </Box>
            </Li>
          ))}
        </Ul>
        <Text variant="body2">
          To learn more about how we use location data, as well as your options to control your
          personal information, please review our{" "}
          <ExternalLink to="https://clipboardhealth.com/privacy-policy">
            Privacy Policy
          </ExternalLink>
          .
        </Text>
      </DialogContent>
      <DialogActions sx={{ flexDirection: "column" }}>
        <Button variant="contained" fullWidth onClick={() => onAllowLocationClick?.()} size="large">
          Go To Settings
        </Button>
        <Button variant="outlined" fullWidth onClick={() => onDeny?.()} size="large">
          Not Now
        </Button>
      </DialogActions>
    </Dialog>
  );
}
