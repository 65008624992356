import { FC } from "react";
import "./style.scss";

interface ToolTipProps {
  message: string;
}
const ToolTipCard: FC<ToolTipProps> = ({ message }) => {
  return <span className="tooltip">{message}</span>;
};

export default ToolTipCard;
