import { environmentConfig } from "@src/appV2/environment";
import { logApiFailureEvent } from "@src/lib/analytics";
import request from "superagent";

import { getAuthHeader } from "../../superagent";

export const updateAgentData = async (agentId, data): Promise<any> => {
  try {
    const res = await request
      .put(`${environmentConfig.REACT_APP_BASE_API_URL}/agentProfile/put`)
      .set(await getAuthHeader())
      .send({
        agentId,
        ...data,
      });
    return res;
  } catch (err) {
    logApiFailureEvent(err);
    throw err;
  }
};
