import { IonButton, IonContent, IonIcon, IonLabel, IonListHeader, IonModal } from "@ionic/react";
import { closeCircleOutline } from "ionicons/icons";
import { FC } from "react";

import { UrgentShiftInfoModalProps } from "./model";

import "./style.scss";

const UrgentShiftsInfoModal: FC<UrgentShiftInfoModalProps> = ({ visible, hideModal, items }) => (
  <IonModal isOpen={visible} cssClass="urgent-shifts-info-modal" onDidDismiss={hideModal}>
    <IonContent className="content">
      <IonListHeader className="info-header">
        <IonLabel>What are Urgent Shifts?</IonLabel>
        <IonButton
          data-testid="urgent-shift-info-modal-close-btn"
          onClick={hideModal}
          fill="clear"
          className="close-button"
        >
          <IonIcon icon={closeCircleOutline} slot="icon-only" />
        </IonButton>
      </IonListHeader>
      <div className="modal-body">
        <ul>
          {items.map((message) => (
            <li key={message}>{message}</li>
          ))}
        </ul>
      </div>
    </IonContent>
  </IonModal>
);

export default UrgentShiftsInfoModal;
